class ActionTypes {}
ActionTypes.ALL_RESOURCES_LOADED = "ALL_RESOURCES_LOADED"
ActionTypes.RESET_APPLICATION = "RESET_APPLICATION"
ActionTypes.SITE_SELECTED = "SITE_SELECTED"
ActionTypes.NETWORK_SELECTED = "NETWORK_SELECTED"
ActionTypes.NOTIFICATION_ADD = "NOTIFICATION_ADD"
ActionTypes.NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE"
ActionTypes.CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS"
ActionTypes.APP_ACTIVE = "APP_ACTIVE"
ActionTypes.APP_INACTIVE = "APP_INACTIVE"
ActionTypes.BACKEND_VERSION_RESPONSE = "BACKEND_VERSION_RESPONSE"
ActionTypes.CUSTOM_INTERFACES_LOADED_V2 = "CUSTOM_INTERFACES_LOADED_V2"
ActionTypes.SAVED_FILTERS_LOADED = "SAVED_FILTERS_LOADED"
ActionTypes.ADD_SAVED_FILTERS = "ADD_SAVED_FILTERS"
ActionTypes.DELETE_SAVED_FILTERS = "DELETE_SAVED_FILTERS"
ActionTypes.RESET_NETWORK_TAGS = "RESET_NETWORK_TAGS"
ActionTypes.RESET_NETWORK_TAGS_V2 = "RESET_NETWORK_TAGS_v2"
ActionTypes.NETWORK_TAGS_RESPONSE = "NETWORK_TAGS_RESPONSE"
ActionTypes.NETWORK_TAGS_RESPONSE_V2 = "NETWORK_TAGS_RESPONSE_V2"
export default ActionTypes
