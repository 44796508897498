import Moment from "moment"
import Consts from "../app/consts"
import { getParents } from "./domUtils"
import { FilterContainerSpec } from "../common/components/filterV2/filterSpecs/filterContainerSpec"
import FilterTypes, { Filters } from "../common/consts/filterTypesV2"
import Presets from "../common/consts/presets"
import { store } from "../app/store"
import { deepCompare } from "./arrayUtils"

export function isNullOrUndefined(obj) {
  return obj === null || obj === undefined
}

export function isNullOrEmpty(obj) {
  return isNullOrUndefined(obj) || obj === ""
}

export function getDatesRange(startDate, stopDate) {
  let dateArray = []
  let currentDate = Moment(startDate)
  stopDate = Moment(stopDate)
  while (currentDate <= stopDate) {
    dateArray.push(Moment(currentDate).format(Consts.SERVER_DATE_FORMAT))
    currentDate = Moment(currentDate).add(1, "days")
  }
  return dateArray
}

export function getShortMomentFromNow(fromNow) {
  fromNow = fromNow.replace("minutes", "min")
  fromNow = fromNow.replace("a few seconds ago", "just now")
  return fromNow
}

export function downloadFile(data, fileName) {
  let url = URL.createObjectURL(new Blob([data]))
  let a = document.createElement("a")
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export function genericCompareFunc(a, b, sortDirection) {
  let compareResult = sortDirection === "ASC" ? 1 : -1

  if (isNullOrUndefined(a)) {
    return 1
  }
  if (isNullOrUndefined(b)) {
    return -1
  }
  if (a <= b) {
    return -compareResult
  }
  return compareResult
}

export function objToMap(obj, excludeKeys = null) {
  let map = new Map()
  Object.keys(obj).forEach((k) => {
    if (excludeKeys === null || !excludeKeys.includes(k)) {
      map.set(k, obj[k])
    }
  })

  return map
}

export function mapToObj(map, excludeKeys = null) {
  let obj = {}

  // todo: this won't cascade and check child properties... we should call it recursively by checking prop type.
  for (let prop of map) {
    obj[prop[0]] = prop[1]
  }

  return obj
}

export function mapFromArrayOfObjects(objects, key) {
  let map = new Map()
  if (!isNullOrUndefined(objects)) {
    objects.forEach((obj) => {
      map.set(obj[key], obj)
    })
  }

  return map
}

export function focusParentWithClass(event, className) {
  if (event.keyCode === 40) {
    var input = event.currentTarget
    var parents = getParents(input)

    for (var i = 0; i < parents.length; i++) {
      var parent = parents[i]
      if (parent.classList && parent.classList.contains(className)) {
        parent.focus()
        break
      }
    }
  }
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value)
}

export function uuid4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function nFormatter(num, digits) {
  let si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ]
  let rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol
}

export function getAllURLParameters(pageLocationSearch = null) {
  if (!pageLocationSearch) {
    pageLocationSearch = window.location.search.substring(1)
  }

  let urlVariables = pageLocationSearch.split("&")
  let result = {}

  for (let i = 0; i < urlVariables.length; i++) {
    let parameterName = urlVariables[i].split("=")
    result[parameterName[0]] = parameterName[1]
  }

  return result
}

export function retrieveSortedAndFiltered(allItems, filters, fieldNameToSortBy, sortDirection) {
  if (!allItems) {
    return null
  }

  let resolve = (path, obj) => {
    return path.split(".").reduce((prev, curr) => {
      return prev ? prev[curr] : null
    }, obj || self)
  }

  let filterMap = filters
  let sortedAndFiltered = allItems.slice()

  if (filterMap.size !== 0) {
    let filterSpec = new FilterContainerSpec(filters)
    sortedAndFiltered = sortedAndFiltered.filter((article) => {
      return filterSpec.isSatisfied(article)
    })
  }

  // Finally sort according to the selected sorting field and sorting direction
  if (fieldNameToSortBy.includes(".")) {
    // If we're sorting by a complex (inner) field, we first resolve the field and "flatten" it so that the article
    // will also contain the value flattened so we won't need to repeat this process again while already sorting.
    sortedAndFiltered = sortedAndFiltered.map((item) => {
      return { ...item, [fieldNameToSortBy]: resolve(fieldNameToSortBy, item) }
    })
  }

  // Using the "timsort" sorting algorithm instead of the native sort for better performance on large arrays
  sortedAndFiltered.timsort((a, b) => genericCompareFunc(a[fieldNameToSortBy], b[fieldNameToSortBy], sortDirection))

  return sortedAndFiltered
}
export function filtersToObj(filters) {
  const filtersObject = {}
  filters.forEach((value, key) => {
    filtersObject[key] = value
  })
  return filtersObject
}

export function serializeFilters(filters) {
  let serializedFilters = []

  filters.forEach((filterType) => {
    filterType.includeFilters.forEach((filterItem) => {
      let filter = {
        filterName: filterItem.filterName,
        filterValue: filterItem.filterValue,
        filterType: filterItem.filterType,
        filterOperator: filterItem.filterOperator,
        isExclude: false,
      }

      if (filterItem.filterValueName) {
        filter.filterValueName = filterItem.filterValueName
      }

      serializedFilters.push(filter)
    })

    filterType.excludeFilters.forEach((filterItem) => {
      let filter = {
        filterName: filterItem.filterName,
        filterValue: filterItem.filterValue,
        filterType: filterItem.filterType,
        filterOperator: filterItem.filterOperator,
        isExclude: true,
      }

      if (filterItem.filterValueName) {
        filter.filterValueName = filterItem.filterValueName
      }

      serializedFilters.push(filter)
    })
  })

  return serializedFilters
}

export function deserializeFilters(filters, templates) {
  let deserializedFilters = filters.map((filterItem) => {
    let filterValue = filterItem.filterValue.slice()

    if (!isNullOrUndefined(templates[filterItem.filterType])) {
      if (
        templates[filterItem.filterType].filterValueType === FilterTypes.filterValueTypes.DATE &&
        !Presets.FILTER_PRESETS.includes(filterValue[0])
      ) {
        filterValue[0] = Moment(filterValue[0])

        if (filterValue[1]) {
          filterValue[1] = Moment(filterValue[1])
        }
      }

      let filterName = templates[filterItem.filterType].filterName

      if (!templates[filterItem.filterType].editable && !filterItem.filterValueName) {
        filterName = filterItem.filterName
      }

      return Object.assign({}, templates[filterItem.filterType], filterItem, {
        filterValue,
        filterName, // we override the filter name for backward compatibility
      })
    }
  })

  return deserializedFilters
}

export function deserializeDateFilterPreset(firstValue, secondValue = null) {
  if (Presets.FILTER_PRESETS.includes(firstValue)) {
    return {
      firstValue: Presets.PRESETS.get(firstValue).startDate,
      secondValue: Presets.PRESETS.get(firstValue).endDate,
    }
  }

  return {
    firstValue: Moment(firstValue).startOf("day"),
    secondValue: secondValue ? Moment(secondValue).startOf("day") : null,
  }
}

export function retrieveDeserializePermanentFilters() {
  let permanentFilters = null
  let savedFilters = store.getState().profile.savedFilters

  if (savedFilters) {
    savedFilters.forEach((savedFiltersObj) => {
      // We're taking the last permanent filter if there are multiple permanent filters
      if (
        savedFiltersObj.section_id === FilterTypes.filterSections.CAMPAIGNS &&
        savedFiltersObj.is_permanent === true
      ) {
        permanentFilters = savedFiltersObj.filter_data
      }
    })
  }

  if (permanentFilters) {
    return deserializeFilters(permanentFilters, Filters)
  }

  return null
}

export function buildPermanentFilterMap(filtersArr, filterMap) {
  filtersArr.forEach((value) => {
    let filterByType
    let foundSameFilter = false
    value.isPermanent = true

    if (value.shouldOverrideFilter || !filterMap.get(value.filterType)) {
      filterByType = {
        includeFilters: [],
        excludeFilters: [],
      }
    } else {
      let currentFilters
      filterByType = filterMap.get(value.filterType)

      if (value.isExclude) {
        currentFilters = filterByType.excludeFilters
      } else {
        currentFilters = filterByType.includeFilters
      }

      currentFilters.forEach((filter) => {
        if (deepCompare(filter.filterValue, value.filterValue)) {
          foundSameFilter = true
        }
      })
    }

    if (!foundSameFilter) {
      if (value.isExclude) {
        filterByType.excludeFilters.push(value)
      } else {
        filterByType.includeFilters.push(value)
      }
      filterMap.set(value.filterType, filterByType)
    }
  })

  return filterMap
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) return true

  if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  return true
}
