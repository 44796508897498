import SettingsPageActionTypes from "./settingsPageActionTypes"

const settingsPageInitialState = {
  isLoading: false,
  account: {
    selectedNetwork: null,
    hideInactiveSites: false,
    fbUserName: null,
    fbError: null,
    adAccountItems: [],
  },
  campaignCreation: {
    isLoading: false,
    networkProviderAccounts: [],
    isNetworkTargetingGroupPresetsLoading: false,
    networkTargetingGroupPresets: [],
  },
}

function settingsPageReducer(state = settingsPageInitialState, action) {
  if (state === undefined || action === undefined) {
    return []
  }

  switch (action.type) {
    case SettingsPageActionTypes.SETTINGS_PAGE_LOADING:
      return Object.assign({}, state, {
        isLoading: true,
      })

    case SettingsPageActionTypes.FETCH_ACCOUNT_SETTINGS_RESPONSE:
      return Object.assign({}, state, {
        isLoading: false,
        account: Object.assign({}, state.account, {
          hideInactiveSites: action.campaignSettings.hide_inactive_sites,
          fbUserName: action.campaignSettings.fb_user_name,
          fbError: action.campaignSettings.fb_error,
        }),
      })

    case SettingsPageActionTypes.NETWORK_TARGETING_GROUP_PRESETS_LOADING: {
      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          isNetworkTargetingGroupPresetsLoading: true,
          networkTargetingGroupPresets: [],
        }),
      })
    }

    case SettingsPageActionTypes.FETCH_NETWORK_TARGETING_GROUP_PRESETS_RESPONSE: {
      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          isNetworkTargetingGroupPresetsLoading: false,
          networkTargetingGroupPresets: action.networkTargetingGroupPresets,
        }),
      })
    }

    case SettingsPageActionTypes.DELETE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE: {
      let newNetworkTargetingGroupPresets = state.campaignCreation.networkTargetingGroupPresets.slice()
      newNetworkTargetingGroupPresets = newNetworkTargetingGroupPresets.filter(
        (targetingGroupPreset) => targetingGroupPreset.id !== action.groupId
      )

      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          networkTargetingGroupPresets: newNetworkTargetingGroupPresets,
        }),
      })
    }

    case SettingsPageActionTypes.ADD_NETWORK_TARGETING_GROUP_PRESET_RESPONSE: {
      let newNetworkTargetingGroupPresets = state.campaignCreation.networkTargetingGroupPresets.slice()
      newNetworkTargetingGroupPresets.push(action.networkTargetingGroupPreset)

      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          networkTargetingGroupPresets: newNetworkTargetingGroupPresets,
        }),
      })
    }

    case SettingsPageActionTypes.UPDATE_NETWORK_TARGETING_GROUP_PRESET_RESPONSE: {
      let newNetworkTargetingGroupPresets = state.campaignCreation.networkTargetingGroupPresets.slice()
      newNetworkTargetingGroupPresets = newNetworkTargetingGroupPresets.map((preset) => {
        if (preset.id === action.networkTargetingGroupPreset.id) {
          return action.networkTargetingGroupPreset
        }

        return preset
      })

      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          networkTargetingGroupPresets: newNetworkTargetingGroupPresets,
        }),
      })
    }

    case SettingsPageActionTypes.UPDATE_ACCOUNT_SETTINGS:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          hideInactiveSites: action.campaignSettings.hideInactiveSites,
        }),
      })

    case SettingsPageActionTypes.ACCOUNT_SETTINGS_SELECT_NETWORK:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          selectedNetwork: action.selectedNetwork,
          fbUserName: null,
          fbError: null,
          adAccountItems: [],
        }),
      })

    case SettingsPageActionTypes.ACCOUNT_SETTINGS_RESET_NETWORK_PROVIDER_ACCOUNTS:
      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          networkProviderAccounts: [],
        }),
      })

    case SettingsPageActionTypes.ACCOUNT_SETTINGS_LOADING_NETWORK_PROVIDER_ACCOUNTS:
      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          isLoading: action.isLoading,
        }),
      })

    case SettingsPageActionTypes.ACCOUNT_SETTINGS_UPDATE_NETWORK_PROVIDER_ACCOUNTS:
      return Object.assign({}, state, {
        campaignCreation: Object.assign({}, state.campaignCreation, {
          networkProviderAccounts: action.providerAccounts,
          isLoading: false,
        }),
      })

    case SettingsPageActionTypes.FETCH_AD_ACCOUNTS_RESPONSE:
      return Object.assign({}, state, {
        account: Object.assign({}, state.account, {
          adAccountItems: action.adAccounts,
        }),
      })

    case SettingsPageActionTypes.UPDATE_SITE_PROVIDER_ACCOUNT_RESPONSE:
      return {
        ...state,
        account: {
          ...state.account,
          adAccountItems: {
            ...state.account.adAccountItems,
            [action.providerAccountId]: {
              ...state.account.adAccountItems[action.providerAccountId],
              site_ids: action.siteIds,
            },
          },
        },
      }
    default:
      return state
  }
}

export { settingsPageReducer }
